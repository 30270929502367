import React from "react"
import styles from "../common.module.scss"
import { motion } from "framer-motion"
import ProjectStatusBar from "../common/ProjectStatusBar"

export default function index() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="PubSubManager"
    >
      <div className={styles.devProjectPage}>
        <header className={styles.pageHeader}>
          <h1 className={styles.pageTitle}>Literature Subscription App</h1>

          <ProjectStatusBar
            statusText="In progress"
            githubUrl="https://github.com/isaacd8k/subs-delivery-manager"
          />
        </header>

        <main className={styles.mainContent}>
          {/* Specifications / requirements */}
          <section className={styles.mainSection}>
            <h2>Project Spec</h2>
            <p>
              TASK: build a web-based orders/subscription manager for delivery
              coordination team of a local publications sub-distributor. It
              should allow:
            </p>
            <ul>
              <li>Entering and editing customer information</li>

              <li>
                Registering one-time orders and subscribing customers to
                periodical publications (recurring orders)
              </li>

              <li>Tracking order status from order placement to delivery</li>

              <li>Tracking periodical publications status</li>

              <li>
                Creating subscriber groups (units of subscribers) and making
                batch order updates to all members of the group
              </li>

              <li>
                Granulated security permissions (read/write vs read-only) to
                resources based on login identity
              </li>
            </ul>
          </section>

          {/* Scope of work */}
          <section className={styles.mainSection}>
            <h2>Scope of work</h2>
            <p>
              The app will be developed and delivered as a prototype with the
              possibility of building out a more robust system in the future, if
              the concept is approved. In order to handle the complexity of
              security/authentication, database provisioning, and API design, we
              will leverage the AWS Amplify and AppSync technologies. Also, if
              later on there is a need for federated login with the company's
              resources we can use IAM for a Single Sign On (SSO) experience.
              <br />
              <br />
              <h6>Chosen technologies:</h6>
              <ul>
                <li>Amazon AWS Amplify</li>
                <li>Frontend server technology: NextJS on EC2</li>
                <li>User authentication with AWS IAM</li>
              </ul>
            </p>
            {/* What will I be building? What will it include/not include
          what are the foreseen costs/dev time associated with the requirements */}
            {/* Sample pics of final design */}
          </section>

          {/* Development stages */}
          <section className={styles.mainSection}>
            <h2>Development stages</h2>
            <p>
              Since we will be using AWS DynamoDB as the database technology, it
              is imperative to correctly model the application data requirements
              and data access patterns. Therefore, the project will begin with
              research into application use-cases.
            </p>
            <br />
            <ol>
              <li>User research</li>
              <li>Validate initial application requirements</li>
              <li>Create rough mockups of proposed app views and actions</li>
              <li>
                Present to stakeholders and iterate steps 3 and 4 as necessary
              </li>
              <li>After concepts are approved, begin app development</li>
              <li>
                Usability / end-user testing (prepare for launch & delivery)
              </li>
              <li>Delivery</li>
            </ol>

            {/* In what order will I approach the steps/stages. Why?
        What is the projected timeline & justification */}
          </section>

          {/* Progress */}
          <section className={styles.mainSection}>
            <h2>Progress</h2>
            <p>
              Initial data requirements were gathered and application
              development has started.
            </p>
            {/* Where am I */}

            {/* Next steps */}

            {/* Challenges */}
          </section>
        </main>
      </div>
    </motion.div>
  )
}
